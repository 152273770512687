import React , { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import cn from 'classnames';

const TeamTool = ({ t }) => {
  const { user, logoutUser } = useAuth();
  const navigate = useNavigate();
  const [toolToggle, setToolToggle] = useState('tool-build');
  return (
      <div className='page-teamtool'>
        <div className='main-wrap'>
          <div className='team-tool-navigation'>
            <div className='k-btn-toggle-group'>
                <div className='k-btn-toggle-wrap'>
                    <div
                      className={cn('k-btn-toggle',{ active: toolToggle === 'tool-build'})}
                      onClick={ () => {setToolToggle('tool-build')}}
                    >
                          建站工具
                    </div>
                    <div
                      className={cn('k-btn-toggle',{ active: toolToggle === 'tool-alltemp'})}
                      onClick={ () => {setToolToggle('tool-alltemp')}}
                    >
                        所有版型預覽
                    </div>
                </div>
            </div>
          </div>
          <div className="user-info">
            <img src={user.picture} alt="User Avatar" />{user.name} ({user.email})
            -
            <div
              className='logout-text'
              onClick={() => {
                logoutUser();
                navigate("/login");
              }}
            >登出</div>
          </div>
        </div>
      </div>
  );
};

export default withTranslation()(TeamTool);
