import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const { loginUser } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="page-login">
      <div className="login-logo">
          <img
            className="k-logo"
            src="/img/logo-k36-white.svg"
            alt=""
          />
        </div>
      <h2 className="login-title">登入</h2>
      <p className="login-desc">請使用團隊 Gmail 登入</p>
      <div className="login-btn">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const decoded = jwtDecode(credentialResponse.credential);
            loginUser(decoded);
            navigate("/TeamTool");
          }}
          onError={() => console.log("登入失敗")}
          theme="outline"
          type="standard"
          useOneTap={false}
          width="300px"
        />
      </div>
      
    </div>
  );
}