import { useNavigate } from "react-router-dom";
export default function NoAccess() {
  const navigate = useNavigate();
  return (
    <div className="page-loading">
      <p>登入授權失敗</p>
      <div className="desc">你無權訪問此頁面</div>
      <button className="k-btn btn-block btn-primary" onClick={()=>navigate("/Login")}>再次嘗試登入</button>
      <button className="k-btn btn-block" onClick={()=>navigate("/")}>回首頁</button>
    </div>
  );
}