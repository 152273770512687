import { Navigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";

const ALLOWED_EMAILS = ['rubi.liu@tktech.org'];

export default function ProtectedRoute({ children }) {
  const { user } = useAuth();

  if (!user) return <Navigate to="/Login" />;
  if (!ALLOWED_EMAILS.includes(user.email)) return <Navigate to="/NoAccess" />;

  return children;

  
}
