import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {  useDispatch } from "react-redux";
import { withTranslation } from 'react-i18next';
import { localeToName, k36Langs } from 'libraries/enums';
import Navigation from "components/Layout/Navigation";
import cn from 'classnames';
import { animateScroll as scroller } from 'react-scroll';
import { UpdateTemplateWindow } from 'redux/actions/template';

const Header = ({i18n, t}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    showMobileMenu: false,
  });
  const currentLocal = i18n.language;
  const desktopNavigationHandle = (route, section = null) =>{
    const currentPath = location.pathname;
    if(section){
      if(route === currentPath){
        scroller.scrollTo(section, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }else{
        navigate(route+'#'+section);
      }
    }else{
      navigate(route);
      window.scrollTo(0, 0);
    }
  }
  const mobileNavigationHandle = (route, section = null) =>{
    const currentPath = location.pathname;
    if(section){
      if(route === currentPath){
        scroller.scrollTo(section, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }else{
        navigate(route+'#'+section);
      }
    }else{
      window.scrollTo(0, 0);
      navigate(route);
    }
    dispatch(UpdateTemplateWindow(false));
    setState({...state, showMobileMenu:false});
  }
  return(
  <header className="k-header">
    <div className="k-header-inner main-wrap">
      <div className="left">
        <img
          className="k-logo"
          src="/img/logo-k36.svg"
          alt="K36"
          onClick={() => desktopNavigationHandle('/')}
        />
      </div>
      <div className="center">
        <Navigation handleClick={desktopNavigationHandle}/>
      </div>
      <div className="right">
        {/* <button className="k-btn btn-outline-primary btn-contact" onClick={() => desktopNavigationHandle('/', 'homeDemo')}>{t('nav.demo')}</button> */}
        <button className="k-btn btn-primary btn-contact" onClick={() => navigate('/ContactUs')}>{t('nav.contact')}</button>
        <div className="lang-select">
          <select
            value={currentLocal}
            onChange={(e)=>{
              i18n.changeLanguage(e.target.value)
              .then(() => {
                // 例如，移除 URL 中的 lang 參數
                const url = new URL(window.location);
                url.searchParams.delete('lang'); // 刪除 lang 參數
                window.history.replaceState({}, '', url); // 更新 URL
              })
              .catch((error) => {
                console.error(`Error changing language: ${error}`);
              });
            }}
          >
            {k36Langs.map(lang => (
            <option key={`lang-${lang}`} value={lang}>{localeToName[lang]}</option>
            ))}
          </select>
          <div className="lang-toggle">
            <img src={`/img/flag/${currentLocal}.jpg`} alt=""/>
            <span>{localeToName[currentLocal]}</span>
            <i className="material-symbols-outlined"> expand_more </i>
          </div>
        </div>
        <button
          className="btn-menu"
          onClick={()=>setState({...state, showMobileMenu:!state.showMobileMenu})}
        >
          {state.showMobileMenu ? (
            <i className="material-symbols-outlined"> close </i>
          ):(
            <i className="material-symbols-outlined"> menu_open </i>
          )}
          
        </button>
      </div>
    </div>
    <div className={cn('mobile-menu', { open: state.showMobileMenu })}>
      <div></div>
      <Navigation handleClick={mobileNavigationHandle}/>
      <div className='highlight-btn'>
        <button className="k-btn btn-block btn-outline-primary btn-l" onClick={() => mobileNavigationHandle('/', 'homeDemo')}>{t('nav.demo')}</button>
        <button className="k-btn btn-block btn-primary btn-l" onClick={() => mobileNavigationHandle('/ContactUs')}>{t('nav.contact')}</button>
      </div> 
    </div>
  </header>
  )
};

export default withTranslation()(Header);
