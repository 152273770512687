import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'libraries/i18n';
import { Provider } from 'react-redux';
import store from 'redux/store';
import App from 'App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from 'context/AuthContext';
import ProtectedRoute from 'routes/ProtectedRoute';
import Home from 'components/Home/Home';
import CasinoPlatform from 'components/CasinoPlatform/CasinoPlatform';
import SportsPlatform from 'components/SportsPlatform/SportsPlatform';
import LightningPlatform from 'components/LightningPlatform/LightningPlatform';
import GlobalPlatform from 'components/GlobalPlatform/GlobalPlatform';
import InsightForge from 'components/InsightForge/InsightForge';
import DomainAnalyzer from 'components/DomainAnalyzer/DomainAnalyzer';
import TeamTool from 'components/TeamTool/TeamTool';
import LoginPage from 'components/Auth/LoginPage';
import NoAccess from 'components/NoAccess/NoAccess';
import News from 'components/News/News';


const Comps = {
  App,
  Home,
  Template: lazy(() => import(/* webpackPrefetch: true */ 'components/Template/Template')),
  ContactUs: lazy(() => import(/* webpackPrefetch: true */ 'components/ContactUs/ContactUs')),
  ErrorPage: lazy(() => import(/* webpackPrefetch: true */ 'components/ErrorPage/ErrorPage')),
  A: lazy(() => import(/* webpackPrefetch: true */ 'components/Test/A')),
  B: lazy(() => import(/* webpackPrefetch: true */ 'components/Test/B')),
  CasinoPlatform,
  SportsPlatform,
  LightningPlatform,
  GlobalPlatform,
  InsightForge,
  DomainAnalyzer,
  News,
  TeamTool,
  LoginPage,
  NoAccess,
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Comps.App />,
    errorElement: <Comps.ErrorPage />,
    children: [
      {
        index: true,
        element: <Comps.Home />,
      },
      {
        path: 'a',
        element: <Comps.Home />,
      },
      {
        path: 'b',
        element: <Comps.Home />,
      },
      {
        path: 'c',
        element: <Comps.Home />,
      },
      {
        path: 'Template',
        element: <Comps.Template />,
      },
      {
        path: 'ContactUs',
        element: <Comps.ContactUs />,
      },
      {
        path: 'CasinoPlatform',
        element: <Comps.CasinoPlatform />,
      },
      {
        path: 'SportsPlatform',
        element: <Comps.SportsPlatform />,
      },
      {
        path: 'LightningPlatform',
        element: <Comps.LightningPlatform />,
      },
      {
        path: 'GlobalPlatform',
        element: <Comps.GlobalPlatform />,
      },
      {
        path: 'InsightForge',
        element: <Comps.InsightForge />,
      },
      {
        path: 'DomainAnalyzer',
        element: <Comps.DomainAnalyzer />,
      },
      {
        path: 'News',
        element: <Comps.News />,
      },
      {
        path: 'TeamTool',
        element: (
          <ProtectedRoute>
            <Comps.TeamTool />
          </ProtectedRoute>
        ),
      },
      {
        path: 'Login',
        element: <Comps.LoginPage />,
      },
      {
        path: 'NoAccess',
        element: <Comps.NoAccess />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="684305503566-moud3qagctfbbeplh15io7hi03bf0ja3.apps.googleusercontent.com">
      <AuthProvider>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
