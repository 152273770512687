//K36官網語系
export const k36Langs = [ 'en-US', 'th-TH', 'vi-VN', 'zh-CN', ];
//所有語系
export const allLangs = [ 'th-TH','vi-VN','zh-CN','en-US','hi-IN','id-ID','ja-JP','ml-IN','ms-MY','my-MM','ta-IN','zh-TW','pt-BR', 'es-MX', 'km-KH', 'tl-PH', 'ko-KR', 'bn-BD', 'te-IN', 'kn-IN', 'ru-RU', 'ne-NP'];
//語系對應名稱
export const localeToName = {
    'en-US': 'English',
    'zh-CN': '简体中文',
    'zh-TW': '繁體中文',
    'vi-VN': 'Tiếng Việt',
    'th-TH': 'ไทย',
    'id-ID': 'Indonesia',
    'ms-MY': 'Malay',
    'ja-JP': '日本語',
    'hi-IN': 'हिंदी',
    'ta-IN': 'தமிழ்',
    'ml-IN': 'മലയാളം',
    'my-MM': 'မြန်မာ',
    'pt-BR': 'Português',
    'es-MX': 'Español',
    'km-KH': 'ខ្មែរ',
    'ko-KR': '한국어',
    'it-IT': 'Italiano',
    'tl-PH': 'Tagalog',
    'bn-BD': 'Bangla',
    'te-IN': 'తెలుగు',
    'kn-IN': 'ಕನ್ನಡ',
    'ru-RU': 'Русский язык',
    'ne-NP': 'नेपाली',
};
//所有幣值
export const allCurrency = ['THB','VND2','INR','IDR2','IDR','MYR','CNY','PTS','PHP','USD','HKD','BRL','MMK','USDT', 'MXN', 'JPY', 'BDT', 'EUR', 'KRW', 'LAK2', 'PKR', 'PGK', 'AUD'];
//幣值對應名稱
export const CurrencyToName = {
    'THB' : 'THB',
    'VND2' : 'VND(k)',
    'INR' : 'INR',
    'IDR2' : 'IDR(k)',
    'IDR' : 'IDR',
    'MYR' : 'MYR',
    'CNY' : 'CNY',
    'PTS' : 'PTS',
    'PHP' : 'PHP',
    'USD' : 'USD',
    'HKD' : 'HKD',
    'BRL' : 'BRL',
    'MMK' : 'MMK',
    'USDT' : 'USDT',
    'MXN' : 'MXN',
    'JPY' : 'JPY',
    'BDT' : 'BDT',
    'EUR' : 'EUR',
    'KRW' : 'KRW',
    'LAK2' : 'LAK',
    'PKR' : 'PKR',
    'PGK' : 'PGK',
    'AUD' : 'AUD',
};
//所有電話國碼
export const allTel =  [ 'cn', 'mo', 'my', 'th', 'id', 'vn', 'ph', 'kh', 'sg', 'hk', 'np', 'mx', 'us', 'in', 'jp', 'lk', 'nl', 'gb', 'fr', 'de', 'ru', 'pk', 'mm', 'ar', 'au', 'bd', 'ae', 'tz', 'br', 'kr', 'it', 'ng', 'ca', 'la', 'at', 'nz', 'pg', 'sa', 'gh' ];
//所有電話代碼對應號碼
export const allTelCode = { 
    'cn':'86',
    'mo':'853',
    'my':'60',
    'th':'66',
    'id':'62',
    'vn':'84',
    'ph':'63',
    'kh':'855',
    'sg':'65',
    'hk':'852',
    'np':'977',
    'mx':'52',
    'us':'1',
    'in':'91',
    'jp':'81',
    'lk':'94',
    'nl':'31',
    'gb':'44',
    'fr':'33',
    'de':'49',
    'ru':'7',
    'pk':'92',
    'mm':'95',
    'ar':'54',
    'au':'61',
    'bd':'880',
    'ae':'971',
    'tz':'255',
    'br':'55',
    'kr':'82',
    'it':'39',
    'ng':'234',
    'ca':'1',
    'la':'856',
    'at':'43',
    'nz':'64',
    'pg':'675',
    'sa':'966',
    'gh':'233',
};
//所有遊戲類型
export const allGameType =[ 'LIVE','EGAME','SPORTS','MPG','ANIMAL','CHESS','LOTTERY','ESPORTS','MINI_GAME','FINANCE'];
//後台語言對應語系
export const cmsLang = {
    'default': ['zh-CN','en-US'],
    'VND2':    ['zh-CN','en-US','vi-VN'],
    'THB':     ['zh-CN','en-US','th-TH'],
    'IDR2':    ['zh-CN','en-US','id-ID'],
    'IDR':     ['zh-CN','en-US','id-ID']
};
//版型標籤對應
export const tempTagConfig = [
    { id: 1, i18nKey: 'temp.tag.all' },          // 全部
    // { id: 2, i18nKey: 'temp.tag.new' },          // 最新
    { id: 3, i18nKey: 'temp.tag.hot' },          // 全站熱門
    { id: 4, i18nKey: 'temp.tag.vietnam.hot' },  // 越南站熱門
    { id: 5, i18nKey: 'temp.tag.thailand.hot' }, // 泰國站熱門
    { id: 6, i18nKey: 'temp.tag.india.hot' },    // 印度站熱門
];