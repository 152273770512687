import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const GlobalPlatform = ({ t }) => {
  const navigate = useNavigate();
  const desktopNavigationHandle = (route) => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const demoRenderer = () => {
    var demoList = [];
    for (var i = 1; i < 4; i++) {
      demoList.push(
        <div key={`gp-demo${i}-item`} className='demo-item'>
          <img src={`/img/gp-0${i}.png`} alt="" loading='lazy' />
          <div className='main-content'>
            <h4>{t(`gp.demo${i}.title`)}</h4>
            <p>{t(`gp.demo${i}.desc`)}</p>
          </div>
        </div>
      );
    }
    return demoList;
  };
  return (
    <div className='page-gp'>
      <div className='k-btn-toggle-group'>
        <div className='k-btn-toggle-wrap'>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/SportsPlatform')}>{t('nav.sports.platform')}</div>
          <div className='k-btn-sepline'></div>
          
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/LightningPlatform')}>{t('nav.lightning.platform')}</div>
          <div className='k-btn-toggle  active'>{t('nav.global.platform')}</div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/InsightForge')}>{t('nav.InsightForge')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/DomainAnalyzer')}>{t('nav.DomainAnalyzer')}</div>
        </div>
      </div>
      <section className='gp-top'>
        <div className='main-wrap'>
          <div className='small-text-tag'>{t('gp.top.tag')}</div>
          <h1><strong>{t('gp.top.title2')}</strong>{t('gp.top.title1')}</h1>
        </div>
        <div className='kv-gp' >
          <img src={`/img/gp-kv.webp`} alt="lp" loading='lazy' />
        </div>
      </section>
      <section className='gp-demo'>
        <div className="lp-demo-cont">
        <h2 className='home-demo-title mobile-show'>{t('gp.demo.title')}</h2>
        <p className='home-demo-desc mobile-show'>{t('gp.demo.desc')}</p>
        <div className='main-wrap'>
          <div className='left'>
            <h2 className='home-demo-title'>{t('gp.demo.title')}</h2>
            <p className='home-demo-desc'>{t('gp.demo.desc')}</p>
            <div className='home-demo-list'>{demoRenderer()}</div>
          </div>
        </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(GlobalPlatform);
